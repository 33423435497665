import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { createStyles, WithStyles } from "@material-ui/styles";
import React from "react";
import { LocationDetails, LocationId } from "../shared/src/DataTypes";
import PublicAlbumLink from "./PublicAlbumLink";

const styles = (theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    });

interface Props extends WithStyles<typeof styles> {
    onSave: (locationDetails: LocationDetails, pics: Array<File>) => void;
    onCancel: () => void;
    initialTitle: string;
    initialDescription: string;
    isPublic: boolean;
    id?: LocationId;
}

interface State {
    title: string;
    description: string;
    isPublic: boolean;
}

class LocationForm extends React.Component<Props, State> {
    private readonly extraImagesInput: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props);
        this.state = {
            title: props.initialTitle,
            description: props.initialDescription,
            isPublic: props.isPublic,
        };
        this.extraImagesInput = React.createRef();
    }

    private submitEnabled = () => !!this.state.title.trim();
    private submit = () => {
        const { title, description, isPublic } = this.state;
        this.props.onSave({ title, description, isPublic }, [
            ...(this.extraImagesInput.current!.files || []),
        ]);
    };

    render() {
        return (
            <Dialog open={true} className="location-editor">
                <DialogContent>
                    <TextField
                        className={this.props.classes.margin}
                        label="Title (shown on Map)"
                        value={this.state.title}
                        required={true}
                        onChange={event => this.setState({ title: event.target.value })}
                    />
                    <TextField
                        className={this.props.classes.margin}
                        label="Description"
                        value={this.state.description}
                        onChange={event => this.setState({ description: event.target.value })}
                    />
                    <div className={this.props.classes.margin}>
                        <InputLabel htmlFor="extraImages">Additional Images</InputLabel>
                        <Input
                            type="file"
                            name="extraImages"
                            id="extraImages"
                            inputRef={this.extraImagesInput}
                            inputProps={{ multiple: true }}
                        />
                    </div>
                    <PublicAlbumLink
                        id={this.props.id}
                        isPublic={this.state.isPublic}
                        changePublic={isPublic => this.setState({ isPublic })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button type="submit" onClick={this.submit} disabled={!this.submitEnabled()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(LocationForm);
