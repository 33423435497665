import { Point } from "geojson";
import L, { LeafletEventHandlerFn, LeafletMouseEvent } from "leaflet";
import React from "react";
import { connect } from "react-redux";
import { actions } from "../actions/Actions";
import { uploadAlbum } from "../invisible/Internet";
import { LocationDetails, Place, UnsavedPlace } from "../shared/src/DataTypes";
import { Dispatch } from "../utils/Types";
import LocationForm from "./LocationForm";

interface OwnProps {
    pickingLocation: boolean;
    map: L.Map;
    stop: () => void;
    updateAlbumPictures: (location: Place, pics: Array<File>) => void;
}
interface DispatchProps {
    saveNewLocation: (details: UnsavedPlace, pics: Array<File>) => void;
}

type Props = OwnProps & DispatchProps;

interface State {
    editorOpenAtCoords?: Point;
}

// TODO show different pointer

class LocationSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.updateEventHandler(this.props, {} as Props);
    }

    componentWillUnmount() {
        this.updateEventHandler({} as Props, this.props);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        this.updateEventHandler(this.props, prevProps);
    }

    private updateEventHandler(currentProps: Readonly<Props>, prevProps: Readonly<Props>) {
        if (currentProps.pickingLocation && !prevProps.pickingLocation) {
            currentProps.map.on("click", this.clickedOnMap as LeafletEventHandlerFn);
        } else if (!currentProps.pickingLocation && prevProps.pickingLocation) {
            prevProps.map.off("click", this.clickedOnMap as LeafletEventHandlerFn);
        }
    }

    private clickedOnMap = (event: LeafletMouseEvent) => {
        const { lat, lng } = event.latlng;
        this.setState({ editorOpenAtCoords: { type: "Point", coordinates: [lng, lat] } });
    };

    private stopSelecting = () => {
        this.setState({ editorOpenAtCoords: undefined });
        this.props.stop();
    };

    private saveNewLocation = (details: LocationDetails, pics: Array<File>) => {
        this.props.saveNewLocation({ ...details, coords: this.state.editorOpenAtCoords! }, pics);
        this.stopSelecting();
    };

    render() {
        return this.state.editorOpenAtCoords ? (
            <LocationForm
                onSave={this.saveNewLocation}
                onCancel={this.stopSelecting}
                initialTitle=""
                initialDescription=""
                isPublic={false}
            />
        ) : null;
    }
}

export default connect(
    null,
    (dispatch: Dispatch, ownProps: OwnProps) => ({
        saveNewLocation: (place: UnsavedPlace, pics: Array<File>) => {
            dispatch(actions.saveNewAlbum(place));
            uploadAlbum(place).then(album => {
                dispatch(actions.newAlbumFromServer(album));
                ownProps.updateAlbumPictures(album, pics);
            });
        },
    }),
)(LocationSelector);
