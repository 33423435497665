import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import Pause from "@material-ui/icons/Pause";
import PlayArrow from "@material-ui/icons/PlayArrow";
import { createStyles, WithStyles } from "@material-ui/styles";
import React from "react";

const styles = createStyles({
    play: {
        position: "fixed",
        zIndex: 500,
        bottom: "2.5rem",
        right: "2rem",
    },
});

interface Props extends WithStyles<typeof styles> {
    onPlay: () => void;
    onPause: () => void;
    playing: boolean;
    disabled: boolean;
}

class PlayButton extends React.Component<Props> {
    render() {
        return (
            <Fab
                className={this.props.classes.play}
                color="primary"
                onClick={this.props.playing ? this.props.onPause : this.props.onPlay}
                disabled={this.props.disabled}
            >
                {this.props.playing ? <Pause /> : <PlayArrow />}
            </Fab>
        );
    }
}

export default withStyles(styles)(PlayButton);
