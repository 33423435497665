import { Place, VisibleDatedPicture } from "../shared/src/DataTypes";
import { albumDownload, getAlbumsFromBackend } from "./Internet";

export const loadInitialData = async (): Promise<{
    albums: Array<Place>;
    pictures: Array<VisibleDatedPicture>;
}> => {
    const path = window.location.pathname;
    if (path === "/") {
        return getAlbumsFromBackend().then(albums => ({ albums, pictures: [] }));
    } else if (path.split("/")[1] === "album") {
        const albumId = path.split("/")[2];
        return albumDownload(albumId).then(pictures => ({
            pictures,
            albums: [],
        }));
    }
    return { albums: [], pictures: [] };
};
