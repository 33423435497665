import { Timestamp } from "../shared/src/DataTypes";
import EXIF from "./exif-fix";

export function getDate(file: File): Promise<Timestamp | null> {
    return new Promise((resolve, reject) => {
        EXIF.getData(file, function(this: any) {
            const rawDate = EXIF.getTag(this, "DateTimeOriginal");
            if (!rawDate) {
                resolve(null);
                return;
            }
            const dateParts = rawDate.split(" ");
            const date = new Date(
                [dateParts[0].replace(/:/g, "-"), dateParts[1]].join("T") + "+00:00",
            ); // this fixes that the date is in UTC. conversion to actual on-the-ground dates is done in the backend

            if (date) {
                resolve(date.getTime());
            } else {
                resolve(null);
            }
        });
    });
}
