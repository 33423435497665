import along from "@turf/along";
import { lineString } from "@turf/helpers";
import length from "@turf/length";
import { Point } from "geojson";
import { TaggedPicture, Timestamp, VisibleDatedPicture, whereIs } from "../shared/src/DataTypes";

// TODO for time intervals to untagged pictures that are too long (e.g. more than 2 hours), flag and manually tag them
export function inferGeotags(pics: Array<VisibleDatedPicture>) {
    const [untaggedHead, listWithTaggedEnds, untaggedTail] = extractListWithTaggedEnds(
        pics.slice(),
    );

    if (listWithTaggedEnds.length === 0) {
        return []; // No geotags at all :(
    }

    const taggedPictures = fillGeoTags(listWithTaggedEnds);

    const taggedHead = untaggedHead.map(pic => ({
        ...pic,
        coords: null,
        inferredCoords: whereIs(taggedPictures[0]),
    }));
    const taggedTail = untaggedTail.map(pic => ({
        ...pic,
        coords: null,
        inferredCoords: whereIs(taggedPictures[taggedPictures.length - 1]),
    }));

    return [...taggedHead, ...taggedPictures, ...taggedTail];
}

function fillGeoTags(pics: Array<VisibleDatedPicture>) {
    const taggedPics: Array<TaggedPicture> = [];

    if (!pics[0].coords || !pics[pics.length - 1].coords) return []; // The first and last pic must have real coords.

    pics.forEach((pic, i) => {
        if (pic.coords) {
            taggedPics.push({
                ...pic,
                coords: pic.coords,
                inferredCoords: null,
            });
        } else {
            const inferredCoords = findPoint(
                taggedPics[i - 1],
                pic.date,
                findNextGeoTaggedPic(pics, i),
            );
            taggedPics.push({ ...pic, coords: null, inferredCoords });
        }
    });

    return taggedPics;
}

function findPoint(left: TaggedPicture, ownDate: Timestamp, right: TaggedPicture) {
    const totalTime = right.date - left.date;
    if (totalTime <= 0) {
        return whereIs(left);
    }

    const percentage = (ownDate - left.date) / totalTime;
    const line = lineString([whereIs(left).coordinates, whereIs(right).coordinates]);
    const distanceFromLeft = percentage * length(line);

    const { coordinates } = along(line, distanceFromLeft).geometry;

    return { type: "Point", coordinates } as Point;
}

function findNextGeoTaggedPic(pics: Array<VisibleDatedPicture>, startIndex: number) {
    for (let i = startIndex; i < pics.length; i++) {
        if (pics[i].coords) {
            return pics[i] as TaggedPicture;
        }
    }
    return pics[pics.length - 1] as TaggedPicture;
}

function extractListWithTaggedEnds(pics: Array<VisibleDatedPicture>) {
    // We need to have geo data for the first and last pic, otherwise they can't be shown on the map.
    // So let's just assume they are all the same as the first/last actually geotagged pic
    let start = 0,
        end = pics.length;
    for (; start < pics.length; start++) {
        if (pics[start].coords) {
            break;
        }
    }
    for (; end > 0; end--) {
        if (pics[end - 1].coords) {
            break;
        }
    }

    return [pics.slice(0, start), pics.slice(start, end), pics.slice(end, pics.length)];
}
