import { Theme } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { createStyles, WithStyles } from "@material-ui/styles";
import React from "react";
import { LocationId } from "../shared/src/DataTypes";

const styles = (theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    });

interface Props extends WithStyles<typeof styles> {
    isPublic: boolean;
    changePublic: (value: boolean) => void;
    id?: LocationId;
}

class PublicAlbumLink extends React.Component<Props> {
    render() {
        if (!this.props.id) {
            return null;
        }
        return (
            <>
                <FormControlLabel
                    className={this.props.classes.margin}
                    control={
                        <Checkbox
                            checked={this.props.isPublic}
                            onChange={(ev, val) => this.props.changePublic(val)}
                        />
                    }
                    label={"Make album publicly accessible"}
                />
                {this.props.isPublic ? (
                    <>
                        <br />
                        <TextField
                            className={this.props.classes.margin}
                            fullWidth={true}
                            margin="dense"
                            variant="outlined"
                            label="URL"
                            defaultValue={`https://map.arens.ma/album/${this.props.id}`}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </>
                ) : null}
            </>
        );
    }
}

export default withStyles(styles)(PublicAlbumLink);
