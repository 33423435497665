import { Dictionary } from "lodash";
import { combineReducers } from "redux";
import { actions } from "../actions/Actions";
import { Thumbnail } from "../shared/src/DataTypes";
import { isActionOf } from "../utils/ReduxUtils";
import { Reducer } from "../utils/Types";
import { State } from "./RootReducer";

interface ThumbnailState {
    albums: Dictionary<Thumbnail>;
}

const albums: Reducer<Dictionary<Thumbnail>> = (state = {}, action) => {
    if (isActionOf(actions.addAlbumThumbnail)(action)) {
        return { ...state, [action.params.albumId]: action.params.thumbnail };
    }
    return state;
};

export const thumbnailReducer: Reducer<ThumbnailState> = combineReducers({
    albums,
});

export const thumbnailSelectors = {
    getAlbumThumbnails: (state: State) => state.thumbnails.albums,
};
