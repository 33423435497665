import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import Stop from "@material-ui/icons/Stop";
import { createStyles, WithStyles } from "@material-ui/styles";
import React from "react";

const styles = createStyles({
    stop: {
        position: "fixed",
        zIndex: 500,
        bottom: "2.5rem",
        right: "6.5rem",
    },
});

interface Props extends WithStyles<typeof styles> {
    onStop: () => void;
}

class StopButton extends React.Component<Props> {
    render() {
        return (
            <Fab className={this.props.classes.stop} color="primary" onClick={this.props.onStop}>
                <Stop />
            </Fab>
        );
    }
}

export default withStyles(styles)(StopButton);
