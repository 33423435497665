import { Point } from "geojson";
import { fromPairs } from "lodash";
import { Dimensions, LocationId, Timestamp, VisibleDatedPicture } from "../shared/src/DataTypes";
import { DeepReadonly } from "../utils/Types";

export function truthy<T>(x: T | null | undefined): x is T {
    return x !== null && x !== undefined;
}

export function partition<T>(input: Array<T>, partitionFunction: (x: T) => boolean) {
    return input.reduce(
        ([pass, fail], elem) => {
            return partitionFunction(elem) ? [pass.concat(elem), fail] : [pass, fail.concat(elem)];
        },
        [[] as Array<T>, [] as Array<T>],
    );
}

export const pointToLeaflet = (coord: DeepReadonly<Point>) => {
    const [lng, lat] = coord.coordinates;
    return { lat, lng };
};

export const getWidth = (dim: Dimensions | null) => {
    if (!dim) {
        return window.innerWidth * 0.95;
    }
    if (dim.orientation > 4) {
        // These are portrait orientations
        return dim.height;
    } else {
        return dim.width;
    }
};

export const getHeight = (dim: Dimensions | null) => {
    if (!dim) {
        return window.innerWidth * 0.95;
    }
    if (dim.orientation > 4) {
        // These are portrait orientations
        return dim.width;
    } else {
        return dim.height;
    }
};

export function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2)
        return parts
            .pop()!
            .split(";")
            .shift();
}

export const sortPicsByDate = (pics: Array<VisibleDatedPicture>) =>
    pics.sort((a, b) => a.date - b.date);

const separator = ";";
const listKey = "timestampedAlbumIds";

const rememberAlbumAsTimestamped = function(albumId: LocationId) {
    const listOfKnownAlbums = (window.localStorage.getItem(listKey) || albumId).split(separator);
    listOfKnownAlbums.push(albumId);
    const uniqueAlbumIds = new Set(listOfKnownAlbums);
    window.localStorage.setItem(listKey, [...uniqueAlbumIds.values()].join(separator));
};

const getAlbumTimestampKey = function(albumId: LocationId) {
    return "latestTimestampSeen_" + albumId;
};

export const updateLatestTimestamp = (albumId: LocationId, latestTimestamp: Timestamp) => {
    rememberAlbumAsTimestamped(albumId);

    window.localStorage.setItem(getAlbumTimestampKey(albumId), "" + latestTimestamp);
};

export const getLatestAlbumSeenTimestamps = () => {
    const savedAlbumKeys = window.localStorage.getItem(listKey);
    const allSavedAlbums = (savedAlbumKeys && savedAlbumKeys.split(separator)) || [];
    return fromPairs(
        allSavedAlbums.map(albumId => [
            albumId,
            Number(window.localStorage.getItem(getAlbumTimestampKey(albumId))),
        ]),
    );
};
