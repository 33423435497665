import withStyles from "@material-ui/core/styles/withStyles";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import SaveIcon from "@material-ui/icons/Save";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import { createStyles, WithStyles } from "@material-ui/styles";
import React from "react";
import { connect } from "react-redux";
import { API_ROOT } from "../invisible/Constants";
import { selectors, State } from "../reducers/RootReducer";
import { PictureId } from "../shared/src/DataTypes";

const styles = createStyles({
    menuButton: {
        position: "fixed",
        zIndex: 500,
        bottom: "7rem",
        right: "2rem",
    },
});

interface Props extends WithStyles<typeof styles> {
    deletePictures: () => void;
    startAddLocationSelection: () => void;
    hashesToDownload: Array<PictureId>;

    loggedIn: boolean;
}

interface MenuButtonState {
    open: boolean;
}

class MenuButton extends React.Component<Props, MenuButtonState> {
    private readonly downloadForm: React.RefObject<HTMLFormElement>;
    constructor(props: Props) {
        super(props);
        this.state = { open: false };
        this.downloadForm = React.createRef();
    }

    private handleClose = () => {
        this.setState({
            open: false,
        });
    };

    private handleClick = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    private confirmDeletion = () => {
        if (window.confirm("Sure? This will delete the pictures from the server.")) {
            this.props.deletePictures();
        }
        this.handleClick();
    };

    private addLocation = () => {
        this.props.startAddLocationSelection();

        this.handleClick();
    };

    private download = () => {
        if (this.props.hashesToDownload.length === 0) {
            return;
        }
        this.downloadForm.current && this.downloadForm.current.submit();
        this.handleClick();
    };

    render() {
        return (
            <>
                <SpeedDial
                    ariaLabel="Menu"
                    className={this.props.classes.menuButton}
                    FabProps={{ color: "secondary" }}
                    icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<CloseIcon />} />}
                    onClick={this.handleClick}
                    onClose={this.handleClose}
                    open={this.state.open}
                >
                    {this.props.loggedIn ? (
                        <SpeedDialAction
                            icon={<DeleteIcon />}
                            tooltipTitle={"Delete these images"}
                            tooltipOpen
                            onClick={this.confirmDeletion}
                        />
                    ) : null}
                    <SpeedDialAction
                        icon={<SaveIcon />}
                        tooltipTitle={"Download these images"}
                        tooltipOpen
                        onClick={this.download}
                    />
                    {this.props.loggedIn ? (
                        <SpeedDialAction
                            icon={<AddLocationIcon />}
                            tooltipTitle={"Add location"}
                            tooltipOpen
                            onClick={this.addLocation}
                        />
                    ) : null}
                </SpeedDial>
                <form
                    target="_blank"
                    action={API_ROOT + "/download"}
                    ref={this.downloadForm}
                    method="POST"
                >
                    {this.props.hashesToDownload.map(hash => (
                        <input type="hidden" name="hashes[]" value={hash} key={hash} />
                    ))}
                </form>
            </>
        );
    }
}

export default connect((state: State) => ({
    loggedIn: selectors.isLoggedIn(state),
}))(withStyles(styles)(MenuButton));
