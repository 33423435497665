import { combineReducers } from "redux";
import { actions } from "../actions/Actions";
import { LocationId } from "../shared/src/DataTypes";
import { isActionOf } from "../utils/ReduxUtils";
import { Reducer } from "../utils/Types";
import { selectors, State } from "./RootReducer";

interface AlbumEditorState {
    albumBeingEdited: LocationId | null;
}

const albumBeingEdited: Reducer<LocationId | null> = (state = null, action) => {
    if (isActionOf(actions.editAlbum)(action)) {
        return action.params.id;
    } else if (isActionOf(actions.cancelEditingAlbum)(action)) {
        return null;
    }
    return state;
};

export const albumEditorReducer: Reducer<AlbumEditorState> = combineReducers({
    albumBeingEdited,
});

export const albumEditorSelectors = {
    getAlbumBeingEdited: (state: State) =>
        state.albumEditor.albumBeingEdited
            ? selectors.getAlbum(state, state.albumEditor.albumBeingEdited)
            : null,
};
