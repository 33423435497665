import green from "@material-ui/core/colors/green";
import purple from "@material-ui/core/colors/purple";
import CssBaseline from "@material-ui/core/CssBaseline";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import keyBy from "lodash/keyBy";
import "rc-slider/assets/index.css";
import React from "react";
import { batch, connect } from "react-redux";
import { actions } from "../actions/Actions";
import { readMetaDataFromImages, scaleImagesToThumbnails } from "../invisible/ImageAnalyser";
import { loadInitialData } from "../invisible/InitialData";
import {
    albumDownload,
    deleteImages,
    downloadThumbnails,

    uploadPics,
} from "../invisible/Internet";
import { AssemblyLine, AssemblyLinePart } from "../invisible/Worker";
import { selectors, State } from "../reducers/RootReducer";
import { PictureStore, Place, TaggedPicture, VisibleDatedPicture } from "../shared/src/DataTypes";
import { DeepReadonly } from "../utils/Types";
import Display from "./Display";

const styles = createStyles({
    loading: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        zIndex: 2,
    },
    map: {
        height: "100%",
        zIndex: 1,
    },
});

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: purple,
        secondary: green,
    },
});

type Props = WithStyles<typeof styles> & {
    newAlbumFromServer: (album: Place) => void;
    userName: string | null;
};

interface AppState {
    mymap?: L.Map;
    pics: PictureStore;
    confirmationCount: number;
    bigMessage: string;
}

class App extends React.Component<Props, AppState> {
    private readonly imageAnalyser?: AssemblyLine;
    private readonly thumbnailDownloader: AssemblyLine;

    constructor(props: Props) {
        super(props);
        this.state = {
            pics: {},
            confirmationCount: 0,
            bigMessage: "Loading",
        };

        const alreadyRequestedThumbnailHashes = new Set();
        const thumbnailDownloading = new AssemblyLinePart(
            hash =>
                hash in this.state.pics &&
                !alreadyRequestedThumbnailHashes.has(hash) &&
                !!alreadyRequestedThumbnailHashes.add(hash),
            downloadThumbnails,
            hashesAndThumbnails => {
                const cache: PictureStore = {};
                hashesAndThumbnails.forEach(
                    ({ hash, thumbnail }) =>
                        (cache[hash] = { ...this.state.pics[hash], thumbnail }),
                );
                this.setState({
                    pics: { ...this.state.pics, ...cache },
                });
            },
        );
        this.thumbnailDownloader = new AssemblyLine(thumbnailDownloading);

        if (!props.userName) return;
        const metaDataStep = new AssemblyLinePart(
            () => true,
            readMetaDataFromImages(props.userName),
            pics => this.updatePicsInState(pics.filter(pic => !(pic.hash in this.state.pics))),
        );

        const thumbnailStep = new AssemblyLinePart(
            pic => !(pic.hash in this.state.pics) || !this.state.pics[pic.hash].thumbnail,
            scaleImagesToThumbnails,
            this.updatePicsInState,
        );

        const uploadStep = new AssemblyLinePart(
            pic => !(pic.hash in this.state.pics) || !this.state.pics[pic.hash].uploadSuccessful,
            uploadPics,
            okHashes => {
                const cache = this.state.pics;
                okHashes.forEach(hash => (cache[hash].uploadSuccessful = true));
                this.setState({ pics: cache });
            },
        );

        this.imageAnalyser = new AssemblyLine(metaDataStep, thumbnailStep, uploadStep);
    }

    componentDidMount() {
        const mymap = L.map("mapId", { center: [37, 135], zoom: 5 });

        const tileLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution:
                "Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors, " +
                "<a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>, ",
        });

        loadInitialData()
            .then(data => {
                tileLayer.addTo(mymap);
                this.setState({
                    mymap,
                });
                batch(() => data.albums.forEach(this.props.newAlbumFromServer));
                this.updatePicsInState(data.pictures);
            })
            .catch(() => this.setState({ bigMessage: "Not logged in" }));
    }

    private updatePicsInState = (pics: Array<VisibleDatedPicture>) => {
        this.setState({
            pics: {
                ...this.state.pics,
                ...keyBy(pics, "hash"),
            },
        });
    };

    private unloadAlertEventListener = (e: any) => {
        e = e || window.event;
        if (e) {
            e.returnValue = "Not all pictures have been uploaded"; // For IE and Firefox prior to version 4
        }
        return "Not all pictures have been uploaded"; // For Safari
    };

    private startAnalysing = () => {
        if (this.state.confirmationCount <= 0) {
            window.addEventListener("beforeunload", this.unloadAlertEventListener);
        }
        this.setState({ confirmationCount: this.state.confirmationCount + 1 });
    };

    private finishAnalysing = () => {
        if (this.state.confirmationCount <= 1) {
            window.removeEventListener("beforeunload", this.unloadAlertEventListener);
        }
        this.setState({ confirmationCount: this.state.confirmationCount - 1 });
    };

    private analyseImagesInSteps = (album: Place, files: Array<File>) => {
        this.startAnalysing();
        this.imageAnalyser!.startWork(files.map(file => ({ file, album }))).then(
            this.finishAnalysing,
        );
    };

    private deletePictures = (pics: Array<TaggedPicture>) => {
        const hashes = pics.filter(pic => pic.uploadSuccessful).map(pic => pic.hash);
        if (hashes.length) {
            deleteImages(hashes).then(() => {
                const cache = this.state.pics;
                hashes.forEach(hash => delete cache[hash]);
                this.setState({
                    pics: cache,
                });
            });
        }
    };

    private showAlbum = (album: DeepReadonly<Place>) => {
        albumDownload(album._id).then(this.updatePicsInState);
    };

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                {/*<Dropzone onDrop={this.analyseImagesInSteps}>*/}
                {/*    {({ getRootProps, getInputProps }) => */}
                <div id="mapId" className={this.props.classes.map} />
                {/*</Dropzone>*/}
                {this.state.mymap ? (
                    <Display
                        map={this.state.mymap}
                        pics={this.state.pics}
                        loading={this.state.confirmationCount > 0}
                        deletePictures={this.deletePictures}
                        updateAlbumPictures={this.analyseImagesInSteps}
                        downloadThumbnail={hash => this.thumbnailDownloader.startWork([hash])}
                        showAlbum={this.showAlbum}
                    />
                ) : (
                    <h1 className={this.props.classes.loading}>{this.state.bigMessage}</h1>
                )}
            </MuiThemeProvider>
        );
    }
}

export default connect(
    (state: State) => ({ userName: selectors.getUserName(state) }),
    dispatch => ({
        newAlbumFromServer: (album: Place) => dispatch(actions.newAlbumFromServer(album)),
    }),
)(withStyles(styles)(App));
