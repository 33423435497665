import loadImage from "blueimp-load-image";
import { Dimensions, Thumbnail } from "../shared/src/DataTypes";

export function getThumbnail(
    file: File,
    maxWidth: number = 150,
    maxHeight: number = 150,
): Promise<{ thumbnail: Thumbnail; dimensions: Dimensions } | null> {
    return new Promise((resolve, reject) => {
        loadImage(
            file,
            (img, data) => {
                resolve(
                    img instanceof HTMLCanvasElement
                        ? {
                              thumbnail: {
                                  dataUrl: img.toDataURL(),
                                  width: img.width,
                                  height: img.height,
                              },
                              dimensions: {
                                  width: data.originalWidth,
                                  height: data.originalHeight,
                                  orientation: data.exif.get("Orientation"),
                              },
                          }
                        : null,
                );
            },
            {
                canvas: true,
                meta: true,
                orientation: true,
                maxWidth,
                maxHeight,
            },
        );
    });
}
