import { LocationId, Place, Thumbnail, UnsavedPlace } from "../shared/src/DataTypes";
import { actionCreator } from "../utils/ReduxUtils";

export const actions = {
    addAlbumThumbnail: actionCreator(
        "addAlbumThumbnail",
        (albumId: LocationId, thumbnail: Thumbnail) => ({
            params: { albumId, thumbnail },
        }),
    ),

    openAlbumOnMap: actionCreator("openAlbumOnMap", (albumId: LocationId) => ({
        params: { albumId },
    })),

    saveNewAlbum: actionCreator("saveNewAlbum", (album: UnsavedPlace) => ({
        params: { album },
    })),

    newAlbumFromServer: actionCreator("newAlbumFromServer", (album: Place) => ({
        params: { album },
    })),

    editAlbum: actionCreator("editAlbum", (id: LocationId) => ({
        params: { id },
    })),
    cancelEditingAlbum: actionCreator("cancelEditingAlbum"),
};

export type BaseAction = {
    [K in keyof typeof actions]: ReturnType<typeof actions[K]>;
}[keyof typeof actions];
