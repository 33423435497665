import { combineReducers } from "redux";
import { albumEditorReducer, albumEditorSelectors } from "./AlbumEditor";
import { albumReducer, albumSelectors } from "./Albums";
import { thumbnailReducer, thumbnailSelectors } from "./Thumbnails";
import { userReducer, userSelectors } from "./User";

export const rootReducer = combineReducers({
    thumbnails: thumbnailReducer,
    albums: albumReducer,
    albumEditor: albumEditorReducer,
    user: userReducer,
});

export type State = ReturnType<typeof rootReducer>;

export const selectors = {
    ...thumbnailSelectors,
    ...albumSelectors,
    ...albumEditorSelectors,
    ...userSelectors,
};
