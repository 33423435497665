import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import L from "leaflet";
import keyBy from "lodash/keyBy";
import Slider from "rc-slider";
import React from "react";
import { connect } from "react-redux";
import { actions } from "../actions/Actions";
import { inferGeotags } from "../invisible/GeoTagInferrer";
import { updateAlbum } from "../invisible/Internet";
import { pointToLeaflet, sortPicsByDate } from "../invisible/util";
import { selectors, State } from "../reducers/RootReducer";
import { LocationDetails, PictureId, PictureStore, Place, TaggedPicture, whereIs } from "../shared/src/DataTypes";
import { DeepReadonly } from "../utils/Types";
import AlbumsOnMap from "./AlbumsOnMap";
import AnimationControl from "./AnimationControl";
import Gallery from "./Gallery";
import LocationForm from "./LocationForm";
import LocationSelector from "./LocationSelector";
import MenuButton from "./MenuButton";
import PicturesOnMap from "./PicturesOnMap";

const styles = createStyles({
    slider: {
        position: "fixed",

        bottom: "1rem",
        left: "50%",
        transform: "translate(-50%)",

        width: "400px",
        maxWidth: "90vw",

        zIndex: 99999,
    },
});

interface Props extends WithStyles<typeof styles> {
    pics: PictureStore;
    map: L.Map;
    loading: boolean;
    updateAlbumPictures: (location: Place, pics: Array<File>) => void;
    deletePictures: (pics: Array<TaggedPicture>) => void;
    downloadThumbnail: (hash: PictureId) => void;
    showAlbum: (album: DeepReadonly<Place>) => void;

    albumBeingEdited: DeepReadonly<Place> | null;

    cancelEditingAlbum: () => void;
    saveAlbum: (album: Place) => void;
}

export interface AnimationState {
    idsInAnimation: Array<PictureId>;
    currentIndex: number;
}

interface DisplayState {
    animation: AnimationState | null;
    picIdShownInGallery?: PictureId;
    choosingLocation: boolean;
    animationTimeout: number | null;
}

class Display extends React.Component<Props, DisplayState> {
    readonly state: DisplayState = {
        animation: null,
        animationTimeout: null,
        choosingLocation: false,
    };

    private getPicsByHashes = (hashes: Array<PictureId>) => {
        const sortedPicDict = keyBy(this.getSortedPics(), "hash");
        return hashes.map(hash => sortedPicDict[hash]);
    };

    private getSortedPics = () => inferGeotags(sortPicsByDate(Object.values(this.props.pics)));

    private getPicsOnCurrentMap = () => {
        return this.getSortedPics().filter(pic => {
            return this.props.map.getBounds().contains(pointToLeaflet(whereIs(pic)));
        });
    };

    componentDidMount() {
        this.props.map.on("zoomend moveend", () => {
            if (!this.state.animation) {
                this.forceUpdate();
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<DisplayState>): void {
        if (this.props.loading && !prevProps.loading) {
            this.stopAnimation();
        }
    }

    private animationStep = () => {
        this.setState(prevState => {
            if (!prevState.animation) return null;

            let animationTimeout;
            const nextIndex = prevState.animation.currentIndex + 1;
            if (nextIndex < prevState.animation.idsInAnimation.length) {
                animationTimeout = window.setTimeout(this.animationStep, 1000);
                this.props.downloadThumbnail(prevState.animation.idsInAnimation[nextIndex]);
                if (nextIndex + 1 < prevState.animation.idsInAnimation.length) {
                    this.props.downloadThumbnail(prevState.animation.idsInAnimation[nextIndex + 1]);
                }
            } else {
                animationTimeout = window.setTimeout(this.stopAnimation, 1000);
            }
            return {
                animation: {
                    ...prevState.animation,
                    currentIndex: nextIndex,
                },
                animationTimeout,
            };
        });
    };

    private startAnimation = () => {
        if (!this.state.animation) {
            this.setState({
                animation: {
                    idsInAnimation: this.getPicsOnCurrentMap().map(pic => pic.hash),
                    currentIndex: 0,
                },
            });
        }
        this.animationStep();
    };

    private stopAnimation = () => {
        clearTimeout(this.state.animationTimeout!);
        this.setState({
            animation: null,
            animationTimeout: null,
        });
    };

    private pauseAnimation = () => {
        clearTimeout(this.state.animationTimeout!);
        this.setState({
            animationTimeout: null,
        });
    };

    private interruptAnimation = (num: number) => {
        this.pauseAnimation();
        this.setState(prevState => ({
            animation: { idsInAnimation: prevState.animation!.idsInAnimation, currentIndex: num },
        }));
    };

    private saveEditedLocation = (location: LocationDetails, pics: Array<File>) => {
        this.props.cancelEditingAlbum();
        const updatedAlbum = { ...this.props.albumBeingEdited!, ...location } as Place;
        this.props.updateAlbumPictures(updatedAlbum, pics);
        updateAlbum(updatedAlbum).then(() => this.props.saveAlbum(updatedAlbum));
    };

    private getPicsCurrentlyVisible = () =>
        this.state.animation
            ? this.getPicsByHashes(
                  this.state.animation.idsInAnimation.slice(0, this.state.animation.currentIndex),
              )
            : this.getSortedPics();

    private showPicInGallery = (picIdShownInGallery: PictureId) => {
        if (this.props.loading) return;
        this.pauseAnimation();
        this.setState({ picIdShownInGallery });
    };

    render() {
        return (
            <>
                <Gallery
                    pics={this.getSortedPics()}
                    picIdShownInGallery={this.state.picIdShownInGallery}
                    onClose={() => this.setState({ picIdShownInGallery: undefined })}
                />
                <PicturesOnMap
                    map={this.props.map}
                    pics={this.getPicsCurrentlyVisible()}
                    animation={this.state.animation}
                    animationPlaying={this.state.animationTimeout !== null}
                    showPicInGallery={this.showPicInGallery}
                    downloadThumbnail={this.props.downloadThumbnail}
                />
                <AlbumsOnMap map={this.props.map} showLocation={this.props.showAlbum} />
                {this.getPicsOnCurrentMap().length || this.state.animation ? (
                    <AnimationControl
                        onPlay={this.startAnimation}
                        onPause={this.pauseAnimation}
                        onStop={this.stopAnimation}
                        animationActive={!!this.state.animation}
                        playing={this.state.animationTimeout !== null}
                        disabled={this.props.loading}
                    />
                ) : null}
                {this.props.loading ? (
                    <span className={this.props.classes.slider}>Loading...</span>
                ) : null}
                {this.state.animation ? (
                    <Slider
                        className={this.props.classes.slider}
                        max={this.state.animation.idsInAnimation.length}
                        value={this.state.animation.currentIndex}
                        onChange={this.interruptAnimation}
                    />
                ) : null}
                <LocationSelector
                    map={this.props.map}
                    pickingLocation={this.state.choosingLocation}
                    updateAlbumPictures={this.props.updateAlbumPictures}
                    stop={() => this.setState({ choosingLocation: false })}
                />
                {this.props.albumBeingEdited ? (
                    <LocationForm
                        onSave={this.saveEditedLocation}
                        onCancel={this.props.cancelEditingAlbum}
                        initialTitle={this.props.albumBeingEdited.title}
                        initialDescription={this.props.albumBeingEdited.description}
                        isPublic={this.props.albumBeingEdited.isPublic}
                        id={this.props.albumBeingEdited._id}
                    />
                ) : null}
                <MenuButton
                    deletePictures={() => this.props.deletePictures(this.getPicsOnCurrentMap())}
                    startAddLocationSelection={() => this.setState({ choosingLocation: true })}
                    hashesToDownload={this.getPicsOnCurrentMap().map(pic => pic.hash)}
                />
            </>
        );
    }
}

export default connect(
    (state: State) => ({
        albumBeingEdited: selectors.getAlbumBeingEdited(state),
    }),
    dispatch => ({
        cancelEditingAlbum: () => dispatch(actions.cancelEditingAlbum()),
        saveAlbum: (album: Place) => dispatch(actions.newAlbumFromServer(album)),
    }),
)(withStyles(styles)(Display));
