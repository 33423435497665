import { applyMiddleware, createStore, DeepPartial } from "redux";
import { BaseAction } from "../actions/Actions";
import { Store } from "../utils/Types";
import { rootReducer, State } from "./RootReducer";

export function configureStore(initialState: DeepPartial<State>): Store {
    let middleware = applyMiddleware();

    // Enable redux dev tools in development build
    // if (process.env.NODE_ENV === "development") {
        // TODO don't deploy dev mode
        const devToolsEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        if (devToolsEnhancer) {
            middleware = devToolsEnhancer(middleware);
        }
    // }

    return createStore<State, BaseAction, {}, {}>(rootReducer, initialState as State, middleware);
}
