import L from "leaflet";
import { DEVELOPMENT } from "./invisible/Constants";
import { getCookie, getLatestAlbumSeenTimestamps } from "./invisible/util";

// Stupid workaround for https://github.com/Leaflet/Leaflet/issues/4968
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const cookie = (key: string, val: string) =>
    `${key}=${val};path=/;max-age=${2 ** 25};${DEVELOPMENT ? "secure;samesite=strict" : ""}`;

const login = function() {
    const query = new URLSearchParams(window.location.search);
    const user = query.get("user") || getCookie("user");
    const token = query.get("token") || getCookie("token");

    if (user && token) {
        document.cookie = cookie("user", user);
        document.cookie = cookie("token", token);
    }

    return { user: { name: user } };
};

const getTimestamps = function() {
    try {
        return getLatestAlbumSeenTimestamps();
    } catch (e) {
        return {};
    }
};

export default () => {
    return {
        ...login(),
        albums: { timestamps: getTimestamps() },
    };
};
