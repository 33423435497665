import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { DeepPartial } from "redux";
import "./index.css";
import init from "./init";
import { configureStore } from "./reducers/ConfigureStore";
import { State } from "./reducers/RootReducer";
import * as serviceWorker from "./serviceWorker";
import App from "./visible/App";

const initialState: DeepPartial<State> = init();

ReactDOM.render(
    <Provider store={configureStore(initialState)}>
        <App />
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
