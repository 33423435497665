import { Point } from "geojson";
import EXIF from "./exif-fix";

export function getCoordinates(file: File): Promise<Point | null> {
    return new Promise((resolve, reject) => {
        EXIF.getData(file, function(this: any) {
            let lat = toDecimal(EXIF.getTag(this, "GPSLatitude"));
            if (EXIF.getTag(this, "GPSLatitudeRef") === "S") {
                lat = -lat;
            }
            let lng = toDecimal(EXIF.getTag(this, "GPSLongitude"));
            if (EXIF.getTag(this, "GPSLongitudeRef") === "W") {
                lng = -lng;
            }

            if (lat && lng) {
                resolve({ type: "Point", coordinates: [lng, lat] });
            } else {
                resolve(null);
            }
        });
    });
}

function toDecimal(exifGps: any) {
    return (
        exifGps &&
        exifGps[0].numerator +
            exifGps[1].numerator / (60 * exifGps[1].denominator) +
            exifGps[2].numerator / (3600 * exifGps[2].denominator)
    );
}
