import {
    LocationId,
    PictureId,
    Place,
    Thumbnail,
    UnsavedPlace,
    VisibleDatedPicture,
} from "../shared/src/DataTypes";
import { DeepReadonly } from "../utils/Types";
import { API_ROOT } from "./Constants";
import { sortPicsByDate, updateLatestTimestamp } from "./util";

const nativeFetch = window.fetch;

const fetch: typeof nativeFetch = (url, init) => {
    const options: RequestInit = {
        ...init,
        credentials: "include",
    };
    return nativeFetch(url, options);
};

export const albumDownload = (albumId: LocationId) => {
    const albumPicsFromBackend = getAlbumPicsFromBackend(albumId);
    albumPicsFromBackend
        .then(pics => pics.length && updateLatestTimestamp(albumId, sortPicsByDate(pics)[pics.length - 1].date))
        .catch(); // If the localStorage doesn't work, well, so what
    return albumPicsFromBackend;
};

async function getAlbumPicsFromBackend(albumId: LocationId) {
    return (await fetch(API_ROOT + "/albumimages/" + albumId).then(res => res.json())) as Array<
        VisibleDatedPicture
    >;
}

export async function getAlbumsFromBackend() {
    return (await fetch(API_ROOT + "/albums").then(res => res.json())) as Array<Place>;
}

export const uploadAlbum = async (place: UnsavedPlace): Promise<Place> =>
    await fetch(API_ROOT + "/albums", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(place),
    }).then(res => res.json());

export const updateAlbum = async (place: DeepReadonly<Place>) =>
    await fetch(API_ROOT + "/albums/" + place._id, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(place),
    });

export async function getSingleImage(hash: PictureId) {
    return await fetch(getImageUrl(hash)).then(res => res.blob());
}

export async function uploadPics(pics: Array<VisibleDatedPicture>) {
    const responses = await Promise.all(pics.map(postPic));
    return responses
        .map((res, i) => [[pics[i].hash], res.ok])
        .filter(([hash, ok]) => !!ok)
        .map(([hash, ok]) => (hash as unknown) as string);
}

const postPic = (pic: VisibleDatedPicture) => postDataWithFile("/images", pic);

async function postDataWithFile<T extends { file: File }>(url: string, data: T) {
    const file = data.file;
    delete data.file;
    return await rawFormPost(url, { pic: JSON.stringify(data), file });
}

async function rawFormPost(url: string, body: object) {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => formData.append(key, value));
    return await fetch(API_ROOT + url, {
        method: "POST",
        body: formData,
    });
}

export function getImageUrl(hash: PictureId) {
    return API_ROOT + "/image/" + hash;
}

export async function deleteImages(hashes: Array<PictureId>) {
    return await fetch(API_ROOT + "/images", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ hashes }),
    });
}

export async function downloadThumbnails(hashes: Array<PictureId>) {
    return (await Promise.all(hashes.map(downloadThumbnail))).map(response => ({
        hash: response._id as string,
        thumbnail: response.thumbnail as Thumbnail,
    }));
}
export async function downloadAlbumThumbnails(ids: Array<LocationId>) {
    return (await Promise.all(ids.map(downloadAlbumThumbnail)))
        .filter(x => !!x)
        .map(response => ({
            id: response.albumId as string,
            thumbnail: response.thumbnail as Thumbnail,
        }));
}
async function downloadThumbnail(hash: PictureId) {
    return await fetch(API_ROOT + "/thumbnail/" + hash)
        .then(res => res.json())
        .catch(e => null);
}
async function downloadAlbumThumbnail(id: LocationId) {
    return await fetch(API_ROOT + "/albums/" + id + "/thumbnail")
        .then(res => res.json())
        .catch(e => null);
}
