import React from "react";

import PlayButton from "./PlayButton";
import StopButton from "./StopButton";

interface Props {
    onPlay: () => void;
    onPause: () => void;
    onStop: () => void;
    animationActive: boolean;
    playing: boolean;
    disabled: boolean;
}

export default class AnimationControl extends React.Component<Props> {
    render() {
        return (
            <>
                <PlayButton
                    disabled={this.props.disabled}
                    playing={this.props.playing}
                    onPlay={this.props.onPlay}
                    onPause={this.props.onPause}
                />
                {this.props.animationActive ? <StopButton onStop={this.props.onStop} /> : null}
            </>
        );
    }
}
