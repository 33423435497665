import { combineReducers } from "redux";
import { Reducer } from "../utils/Types";
import { State } from "./RootReducer";

interface UserState {
    name: string | null;
}

const name: Reducer<string | null> = (state = null) => state;

export const userReducer: Reducer<UserState> = combineReducers({
    name,
});

export const userSelectors = {
    isLoggedIn: (state: State) => !!state.user.name,
    getUserName: (state: State) => state.user.name,
};
