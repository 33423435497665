import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import L, { Marker, MarkerClusterGroupOptions, MarkerOptions } from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import React from "react";
import { connect } from "react-redux";
import { pointToLeaflet, sortPicsByDate } from "../invisible/util";
import { selectors, State } from "../reducers/RootReducer";
import { PictureId, TaggedPicture, Timestamp, whereIs } from "../shared/src/DataTypes";
import { AnimationState } from "./Display";

const styles = createStyles({
    thumbnail: { textAlign: "center" },
    pic: {
        borderRadius: "8px",
        border: "1px solid white",
        boxShadow: "0 0 2px 1px black",
        "&.new": {
            borderColor: "red",
        },
    },
    number: {
        position: "absolute",
        right: "0.25rem",
        top: "0.25rem",
        fontSize: "2rem",
        lineHeight: "2rem",
        color: "white",
        textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
        "&.withNew": {
            color: "red",
        },
    },
    uploadInProgress: { opacity: 0.5 },
    inAnimation: {
        opacity: 0.5,
        "&.paused, &.current": {
            opacity: 1,
        },
    },
});

type MyPictureMarker = Marker & {
    pic: TaggedPicture;
};
// Stupid workaround for https://github.com/Leaflet/Leaflet/issues/4968
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

interface OwnProps {
    pics: Array<TaggedPicture>;
    map: L.Map;
    animation: AnimationState | null;
    animationPlaying: boolean;
    showPicInGallery: (picIdShownInGallery: PictureId) => void;
    downloadThumbnail: (hash: PictureId) => void;
}

type Props = WithStyles<typeof styles> &
    OwnProps & {
        latestSeenTimestamp: Timestamp;
    };

class PicturesOnMap extends React.Component<Props> {
    private picMarkers?: L.MarkerClusterGroup;

    componentDidMount() {
        this.updateMarkers(this.props);
    }

    componentDidUpdate() {
        this.updateMarkers(this.props);
    }

    private updateMarkers(props: Props) {
        if (this.picMarkers) {
            props.map.removeLayer(this.picMarkers);
        }
        this.picMarkers = this.defineMarkerClusterGroup();

        this.addPicsToMap(props);

        props.map.addLayer(this.picMarkers);
    }

    private sortedCluster = (markers: Array<Marker>) =>
        sortPicsByDate(markers.map(marker => (marker as MyPictureMarker).pic));
    private oldestPicInCluster = (markers: Array<Marker>) => this.sortedCluster(markers)[0];
    private newestPicInCluster = (markers: Array<Marker>) =>
        this.sortedCluster(markers)[markers.length - 1];

    private defineMarkerClusterGroup = () => {
        let options: MarkerClusterGroupOptions = {
            singleMarkerMode: false,
            iconCreateFunction: cluster => {
                const numUploaded = cluster
                    .getAllChildMarkers()
                    .filter((marker: any) => marker.pic.uploadSuccessful).length;
                const total = cluster.getChildCount();
                const className = `${this.props.classes.thumbnail} ${
                    numUploaded < total ? this.props.classes.uploadInProgress : ""
                }`;
                const icon = L.divIcon({
                    className,
                });

                const thumbnail = this.oldestPicInCluster(cluster.getAllChildMarkers()).thumbnail;
                const countString = `${numUploaded < total ? numUploaded + "/" : ""}${total}`;
                const hasNewImages =
                    this.newestPicInCluster(cluster.getAllChildMarkers()).date >
                    this.props.latestSeenTimestamp;

                if (thumbnail) {
                    L.Util.setOptions(icon, {
                        iconSize: [thumbnail.width, thumbnail.height],
                        html: `<img  class="${this.props.classes.pic}" src="${
                            thumbnail.dataUrl
                        }" alt=""/>
                            <div class="${this.props.classes.number} ${
                            hasNewImages ? "withNew" : ""
                        }">${countString}</div>`,
                    });
                } else {
                    L.Util.setOptions(icon, {
                        iconSize: [150, 150],
                        html: `<div class="${this.props.classes.number}">${countString}</div>`,
                    });
                    const oldestPic = this.oldestPicInCluster(cluster.getAllChildMarkers());
                    if (oldestPic.uploadSuccessful) {
                        this.props.downloadThumbnail(oldestPic.hash);
                    }
                }
                return icon;
            },
        };
        if (this.props.animation) {
            options.disableClusteringAtZoom = 0;
        }
        const markerClusterGroup = L.markerClusterGroup(options);
        markerClusterGroup.on("clusterclick", (ev: any) =>
            this.props.showPicInGallery(
                this.oldestPicInCluster(ev.layer.getAllChildMarkers()).hash,
            ),
        );
        return markerClusterGroup;
    };

    private addPicsToMap(props: Props) {
        const markers = props.pics.map((pic, i) => {
            let icon;

            if (pic.thumbnail) {
                icon = L.icon({
                    iconUrl: pic.thumbnail.dataUrl,
                    iconSize: [pic.thumbnail.width, pic.thumbnail.height],
                    className: `${this.props.classes.pic} ${
                        pic.uploadSuccessful ? "" : this.props.classes.uploadInProgress
                    } ${pic.date > this.props.latestSeenTimestamp ? "new" : ""} ${
                        this.props.animation ? this.props.classes.inAnimation : ""
                    } ${
                        this.props.animation &&
                        this.props.animation.idsInAnimation[
                            this.props.animation.currentIndex - 1
                        ] === pic.hash
                            ? "current"
                            : ""
                    } ${!this.props.animationPlaying ? "paused" : ""}`,
                });
            } else {
                icon = new L.Icon.Default(); // The thumbnail has yet to be generated or downloaded
            }

            const options: MarkerOptions = { icon };
            if (this.props.animation) {
                // https://gis.stackexchange.com/questions/115839/custom-ordering-z-index-of-icons-in-leaflet-layer
                options.zIndexOffset = 200 * i;
            }

            const marker = L.marker(pointToLeaflet(whereIs(pic)), options);
            (marker as MyPictureMarker).pic = pic;

            marker.on("click", () => this.props.showPicInGallery(pic.hash));
            if (!pic.thumbnail && pic.uploadSuccessful) {
                marker.once("add", () => this.props.downloadThumbnail(pic.hash));
            }

            return marker;
        });

        this.picMarkers!.addLayers(markers);
    }

    render() {
        return <></>;
    }
}

export default connect((state: State, ownProps: OwnProps) => ({
    latestSeenTimestamp: selectors.getAlbumLatestSeenTimestamp(
        state,
        (ownProps.pics[0] && ownProps.pics[0].albumId) || "",
    ), // Assuming here that all pictures are in the same album....
}))(withStyles(styles)(PicturesOnMap));
