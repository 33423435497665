import { Dictionary } from "lodash";
import { combineReducers } from "redux";
import { actions } from "../actions/Actions";
import { LocationId, LocationStore, Timestamp, UnsavedPlace } from "../shared/src/DataTypes";
import { isActionOf } from "../utils/ReduxUtils";
import { Reducer } from "../utils/Types";
import { State } from "./RootReducer";

interface AlbumState {
    saved: LocationStore;
    unsaved: UnsavedPlace | null;
    timestamps: Dictionary<Timestamp>;
}

const saved: Reducer<LocationStore> = (state = {}, action) => {
    if (isActionOf(actions.openAlbumOnMap)(action)) {
        return {
            ...state,
            [action.params.albumId]: {
                ...state[action.params.albumId],
                open: true,
            },
        };
    } else if (isActionOf(actions.newAlbumFromServer)(action)) {
        const { album } = action.params;
        return {
            ...state,
            [album._id]: album,
        };
    }
    return state;
};

const unsaved: Reducer<UnsavedPlace | null> = (state = null, action) => {
    if (isActionOf(actions.saveNewAlbum)(action)) {
        return action.params.album;
    } else if (isActionOf(actions.newAlbumFromServer)(action)) {
        return null;
    }
    return state;
};

const timestamps: Reducer<Dictionary<Timestamp>> = (state = {}) => state; // Only set as initialState

export const albumReducer: Reducer<AlbumState> = combineReducers({
    saved,
    unsaved,
    timestamps,
});

export const albumSelectors = {
    getAlbums: (state: State) => Object.values(state.albums.saved), // TODO add the unsaved (or not?)
    getAlbum: (state: State, id: LocationId) => state.albums.saved[id],
    getAlbumLatestSeenTimestamp: (state: State, id: LocationId) => state.albums.timestamps[id] || 0, // albums that I don't know should be new to me
};
