import PhotoSwipe from "photoswipe";
import "photoswipe/dist/default-skin/default-skin.css";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

import "photoswipe/dist/photoswipe.css";
import React from "react";
import { connect } from "react-redux";
import { getImageUrl } from "../invisible/Internet";
import { getHeight, getWidth } from "../invisible/util";
import { selectors, State } from "../reducers/RootReducer";
import { PictureId, TaggedPicture } from "../shared/src/DataTypes";
import "./Gallery.css";

interface Props {
    pics: Array<TaggedPicture>;
    picIdShownInGallery?: PictureId;
    onClose: () => void;
    userName: string | null;
}

interface GalleryState {
    currentPic?: TaggedPicture;
}

class Gallery extends React.Component<Props, GalleryState> {
    private gallery: PhotoSwipe<PhotoSwipe.Options> = new (PhotoSwipe as any)();
    private readonly pswpElement: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.pswpElement = React.createRef();
    }

    componentDidMount() {
        this.initGallery(this.props);
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.picIdShownInGallery !== prevProps.picIdShownInGallery) {
            this.initGallery(this.props);
        }
    }

    private getIndex = (props: Props) =>
        props.pics.findIndex(pic => pic.hash === props.picIdShownInGallery);

    private initGallery(props: Props) {
        this.gallery.close();
        if (this.props.picIdShownInGallery === undefined) {
            return;
        }

        const items = props.pics.map(pic => ({
            msrc: pic.thumbnail ? pic.thumbnail.dataUrl : undefined,
            src: getImageUrl(pic.hash),
            w: getWidth(pic.dimensions),
            h: getHeight(pic.dimensions),
            pic,
        }));

        const options = {
            index: this.getIndex(props),
            shareEl: false,
            loop: false,
        };

        this.gallery = new PhotoSwipe(
            this.pswpElement.current!,
            PhotoSwipeUI_Default,
            items,
            options,
        );

        this.gallery.listen("destroy", this.props.onClose);
        this.gallery.listen("afterChange", () =>
            this.setState({ currentPic: (this.gallery.currItem as any).pic }),
        );
        this.gallery.init();
    }

    render() {
        return (
            <div
                className="pswp"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
                ref={this.pswpElement}
            >
                <div className="pswp__bg" />
                <div className="pswp__scroll-wrap">
                    <div className="pswp__container">
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                        <div className="pswp__item" />
                    </div>
                    <div className="pswp__ui pswp__ui--hidden">
                        <div className="pswp__top-bar">
                            <div className="pswp__counter" />
                            <button
                                className="pswp__button pswp__button--close"
                                title="Close (Esc)"
                            />
                            <button className="pswp__button pswp__button--share" title="Share" />
                            <button
                                className="pswp__button pswp__button--fs"
                                title="Toggle fullscreen"
                            />
                            <button
                                className="pswp__button pswp__button--zoom"
                                title="Zoom in/out"
                            />
                            {this.state.currentPic &&
                            this.state.currentPic.author === this.props.userName ? (
                                <button className={`pswp__button`} title="Edit" />
                            ) : null}
                            <div className="pswp__preloader">
                                <div className="pswp__preloader__icn">
                                    <div className="pswp__preloader__cut">
                                        <div className="pswp__preloader__donut" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                            <div className="pswp__share-tooltip" />
                        </div>
                        <button
                            className="pswp__button pswp__button--arrow--left"
                            title="Previous (arrow left)"
                        />
                        <button
                            className="pswp__button pswp__button--arrow--right"
                            title="Next (arrow right)"
                        />
                        <div className="pswp__caption">
                            <div className="pswp__caption__center" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: State) => ({ userName: selectors.getUserName(state) }))(Gallery);
