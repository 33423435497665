import EXIF from "exif-js";

interface FixedExif {
    getData(url: string | File, callback: any): any;

    getTag(img: any, tag: any): any;

    getAllTags(img: any): any;

    pretty(img: any): string;

    readFromBinaryFile(file: any): any;
}

export default EXIF as FixedExif;
