import { partition } from "./util";

export class AssemblyLinePart<Input, Output> {
    private queue: Array<Input> = [];
    private nextQueue?: AssemblyLinePart<Output, any>;

    constructor(
        private readonly partitionFunction: (x: Input) => boolean,
        private readonly workFunction: (x: Array<Input>) => Promise<Array<Output>>,
        private readonly afterWorkFunction: (result: Array<Output>) => void,
    ) {}

    public async runChunk() {
        const chunk = this.queue.slice(0, 8);
        this.queue.splice(0, 8);
        const result = await this.workFunction(chunk);
        this.afterWorkFunction(result);
        this.nextQueue && this.nextQueue.add(result);
    }

    public add(objects: Array<Input>) {
        const [workOn, skip] = partition(objects, this.partitionFunction);
        this.queue.push(...workOn);
        this.nextQueue && this.nextQueue.add((skip as unknown) as Array<Output>);
    }

    public isExhausted() {
        return this.queue.length === 0;
    }
    public setNextQueue(nextQueue: AssemblyLinePart<Output, any>) {
        this.nextQueue = nextQueue;
    }
}

export class AssemblyLine<A=unknown, B = unknown, C = unknown, D = unknown, E = unknown> {
    private readonly parts: Array<AssemblyLinePart<unknown, unknown>> = [];
    private working = false;

    constructor(part1: AssemblyLinePart<A, B>);
    constructor(part1: AssemblyLinePart<A, B>, part2: AssemblyLinePart<B, C>);
    constructor(
        part1: AssemblyLinePart<A, B>,
        part2: AssemblyLinePart<B, C>,
        part3: AssemblyLinePart<C, D>,
    );
    constructor(
        part1: AssemblyLinePart<A, B>,
        part2: AssemblyLinePart<B, C>,
        part3: AssemblyLinePart<C, D>,
        part4: AssemblyLinePart<D, E>,
    );
    constructor(...parts: Array<AssemblyLinePart<unknown, unknown>>) {
        parts.reduce((a, b) => {
            a.setNextQueue(b);
            return b;
        });
        this.parts.push(...parts);
    }

    private async work() {
        for (let i = 0; ; i++) {
            if (i === this.parts.length) {
                this.working = false;
                break;
            }
            if (!this.parts[i].isExhausted()) {
                await this.parts[i].runChunk();
                await this.work();
                break;
            }
        }
    }

    public async startWork(objects: Array<A>) {
        this.parts[0].add(objects);
        if (!this.working) {
            this.working = true;
            return await this.work();
        }
    }
}
