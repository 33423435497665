export const DEVELOPMENT = process.env.NODE_ENV === "development";

let apiRoot;
if (DEVELOPMENT) {
    apiRoot = "//localhost:11337/api";
} else {
    apiRoot = "/api";
}

export const API_ROOT = apiRoot;
export const NOOP = () => {};
