import { Point } from "geojson";
import {
    Dimensions,
    Place,
    Thumbnail,
    Timestamp,
    VisibleDatedPicture,
} from "../shared/src/DataTypes";
import { getDate } from "./ExifDate";
import { getCoordinates } from "./ExifGps";
import { getHash } from "./Hashes";
import { getThumbnail } from "./Thumbnails";
import { truthy } from "./util";

export const readMetaDataFromImages = (userName: string) => async (
    array: Array<{ file: File; album: Place }>,
) => {
    let pics = array
        .filter(elem => truthy(elem.file))
        .map(elem =>
            Promise.all([
                getCoordinates(elem.file),
                getDate(elem.file),
                getHash(elem.file),
                elem.file,
                elem.album,
            ]),
        );

    const picsWithExtractedData = await Promise.all(pics);
    return picsWithExtractedData.map(ensurePictureHasTimestamp(userName)).filter(truthy);
};

export async function scaleImagesToThumbnails(pics: Array<VisibleDatedPicture>) {
    const picsWithThumbnails = await Promise.all(
        pics.map(pic => Promise.all([getThumbnail(pic.file), pic])),
    );

    return picsWithThumbnails.map(ensurePictureHasThumbnail).filter(truthy);
}

function ensurePictureHasThumbnail(
    picData: Array<{ thumbnail: Thumbnail; dimensions: Dimensions } | VisibleDatedPicture | null>,
) {
    return !!picData[0]
        ? ({
              ...picData[1],
              thumbnail: picData[0]!.thumbnail,
              dimensions: picData[0]!.dimensions,
          } as VisibleDatedPicture)
        : null;
}

const ensurePictureHasTimestamp = (userName: string) => (
    picData: Array<any>, // Thumbnail | Point | Timestamp | string | File | null, Place
) => {
    return !!picData[2]
        ? {
              coords: picData[0] as Point | null,
              date: picData[1] as Timestamp,
              hash: picData[2] as string,
              file: picData[3] as File,
              albumId: (picData[4] as Place)._id,
              uploadSuccessful: false,
              thumbnail: null as Thumbnail | null,
              dimensions: null as Dimensions | null,
              author: userName,
          }
        : null;
};
