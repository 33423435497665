import { ActionAttributes, AnyActionCreator, WithActionType } from "./Types";

function assertNoExtraKeys<CREATOR extends (...args: Array<any>) => ActionAttributes>(
    type: string,
    creatorFnc: CREATOR,
): CREATOR {
    return ((...args: Array<any>) => {
        return creatorFnc(...args);
    }) as any;
}

const knownTypes = new Set<string>();

function assertUniqueType(type: string) {
    if (knownTypes.has(type)) throw new Error(`Multiple action creators defined for "${type}"`);
    knownTypes.add(type);
}

type AddTypeToAction<TYPE extends string, CREATOR_STUB> = CREATOR_STUB extends (
    ...args: infer ARGS
) => infer ACTION
    ? ((...args: ARGS) => { [k in keyof (ACTION & { type: TYPE })]: (ACTION & { type: TYPE })[k] })
    : () => { type: TYPE };
export const actionCreator = <
    ACTION_TYPE extends string,
    CREATOR extends (...args: Array<any>) => ActionAttributes
>(
    type: ACTION_TYPE,
    creatorFnc?: CREATOR,
): AddTypeToAction<ACTION_TYPE, CREATOR> & WithActionType<ACTION_TYPE> => {
    if (creatorFnc && (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development")) {
        // Programmatically check for extra keys until we find a TypeScript solution
        creatorFnc = assertNoExtraKeys(type, creatorFnc);
    }
    if (process.env.NODE_ENV === "development") {
        assertUniqueType(type);
    }

    const creator: any = creatorFnc
        ? (...args: Array<any>) => ({ ...creatorFnc!(...args), type })
        : () => ({ type });
    creator.type = type;
    return creator;
};

export function getType<ACTION_TYPE>(creator: { type?: ACTION_TYPE }): ACTION_TYPE {
    return creator.type!;
}

type ActionTypeGuard<A> = (action: any) => action is A;

export function isActionOf<CREATOR extends AnyActionCreator>(
    creator: CREATOR,
): ActionTypeGuard<ReturnType<CREATOR>>;
export function isActionOf<CREATOR1 extends AnyActionCreator, CREATOR2 extends AnyActionCreator>(
    creator1: CREATOR1,
    creator2: CREATOR2,
): ActionTypeGuard<ReturnType<CREATOR1> | ReturnType<CREATOR2>>;
export function isActionOf<
    CREATOR1 extends AnyActionCreator,
    CREATOR2 extends AnyActionCreator,
    CREATOR3 extends AnyActionCreator
>(
    creator1: CREATOR1,
    creator2: CREATOR2,
    creator3: CREATOR3,
): ActionTypeGuard<ReturnType<CREATOR1> | ReturnType<CREATOR2> | ReturnType<CREATOR3>>;
export function isActionOf<
    CREATOR1 extends AnyActionCreator,
    CREATOR2 extends AnyActionCreator,
    CREATOR3 extends AnyActionCreator,
    CREATOR4 extends AnyActionCreator
>(
    creator1: CREATOR1,
    creator2: CREATOR2,
    creator3: CREATOR3,
    creator4: CREATOR4,
): ActionTypeGuard<
    ReturnType<CREATOR1> | ReturnType<CREATOR2> | ReturnType<CREATOR3> | ReturnType<CREATOR4>
>;
export function isActionOf<
    CREATOR1 extends AnyActionCreator,
    CREATOR2 extends AnyActionCreator,
    CREATOR3 extends AnyActionCreator,
    CREATOR4 extends AnyActionCreator,
    CREATOR5 extends AnyActionCreator
>(
    creator1: CREATOR1,
    creator2: CREATOR2,
    creator3: CREATOR3,
    creator4: CREATOR4,
    creator5: CREATOR5,
): ActionTypeGuard<
    | ReturnType<CREATOR1>
    | ReturnType<CREATOR2>
    | ReturnType<CREATOR3>
    | ReturnType<CREATOR4>
    | ReturnType<CREATOR5>
>;
export function isActionOf<
    CREATOR1 extends AnyActionCreator,
    CREATOR2 extends AnyActionCreator,
    CREATOR3 extends AnyActionCreator,
    CREATOR4 extends AnyActionCreator,
    CREATOR5 extends AnyActionCreator,
    CREATOR6 extends AnyActionCreator
>(
    creator1: CREATOR1,
    creator2: CREATOR2,
    creator3: CREATOR3,
    creator4: CREATOR4,
    creator5: CREATOR5,
    creator6: CREATOR6,
): ActionTypeGuard<
    | ReturnType<CREATOR1>
    | ReturnType<CREATOR2>
    | ReturnType<CREATOR3>
    | ReturnType<CREATOR4>
    | ReturnType<CREATOR5>
    | ReturnType<CREATOR6>
>;
export function isActionOf<
    CREATOR1 extends AnyActionCreator,
    CREATOR2 extends AnyActionCreator,
    CREATOR3 extends AnyActionCreator,
    CREATOR4 extends AnyActionCreator,
    CREATOR5 extends AnyActionCreator,
    CREATOR6 extends AnyActionCreator,
    CREATOR7 extends AnyActionCreator
>(
    creator1: CREATOR1,
    creator2: CREATOR2,
    creator3: CREATOR3,
    creator4: CREATOR4,
    creator5: CREATOR5,
    creator6: CREATOR6,
    creator7: CREATOR7,
): ActionTypeGuard<
    | ReturnType<CREATOR1>
    | ReturnType<CREATOR2>
    | ReturnType<CREATOR3>
    | ReturnType<CREATOR4>
    | ReturnType<CREATOR5>
    | ReturnType<CREATOR6>
    | ReturnType<CREATOR7>
>;
export function isActionOf(...creators: Array<any>) {
    return (action: any) => creators.map(creator => creator.type).includes(action.type);
}
