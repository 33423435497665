import { Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { createStyles, WithStyles } from "@material-ui/styles";
import L, { Marker } from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { Dictionary } from "lodash";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { batch, connect } from "react-redux";
import { actions } from "../actions/Actions";
import { downloadAlbumThumbnails } from "../invisible/Internet";
import { pointToLeaflet } from "../invisible/util";
import { AssemblyLine, AssemblyLinePart } from "../invisible/Worker";
import { selectors, State } from "../reducers/RootReducer";
import { LocationId, Place, Thumbnail } from "../shared/src/DataTypes";
import { DeepReadonly, DeepReadonlyArray } from "../utils/Types";

const albumPictureShape = {
    borderRadius: "30%",
    height: "100%",
    width: "100%",
};
const styles = (theme: Theme) =>
    createStyles({
        album: { ...albumPictureShape, border: "1px solid white", boxShadow: "0 0 2px 1px black" },
        albumCover: {
            ...albumPictureShape,
            position: "absolute",
            top: 0,
            opacity: 0.5,
            background: "white",
            ".open &": { background: theme.palette.primary.main },
        },
        icon: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            ".open &": { color: "white" },
        },
        location: { borderRadius: "50%" },
    });

type MyLocationMarker = Marker & {
    album: DeepReadonly<Place>;
};

interface Props extends WithStyles<typeof styles> {
    map: L.Map;
    showLocation: (location: DeepReadonly<Place>) => void;

    albums: DeepReadonlyArray<Place>;
    albumThumbnails: Dictionary<Thumbnail>;

    openAlbumOnMap: (albumId: LocationId) => void;
    editAlbum: (albumId: LocationId) => void;
    addAlbumThumbnail: (albumId: LocationId, thumbnail: Thumbnail) => void;
}

class AlbumsOnMap extends React.Component<Props> {
    private locationMarkers?: L.LayerGroup;
    private readonly thumbnailDownloader: AssemblyLine;

    constructor(props: Props) {
        super(props);

        const alreadyRequestedAlbumIds = new Set();
        const thumbnailDownloading = new AssemblyLinePart(
            id =>
                !(id in props.albumThumbnails) &&
                !alreadyRequestedAlbumIds.has(id) &&
                !!alreadyRequestedAlbumIds.add(id),
            downloadAlbumThumbnails,

            idsAndThumbnails =>
                batch(() =>
                    idsAndThumbnails.forEach(({ id, thumbnail }) =>
                        props.addAlbumThumbnail(id, thumbnail),
                    ),
                ),
        );

        this.thumbnailDownloader = new AssemblyLine(thumbnailDownloading);
    }
    componentDidMount() {
        this.updateMarkers();
    }

    componentDidUpdate() {
        this.updateMarkers();
    }

    private clickedOnLocation = (
        event: any /*sourceTarget type is missing on LeafletMouseEvent*/,
    ) => {
        const { album } = event.sourceTarget as MyLocationMarker;
        if (!album.open) {
            this.props.openAlbumOnMap(album._id);
            this.props.showLocation(album);
        } else {
            this.props.editAlbum(album._id);
        }
    };

    private updateMarkers() {
        if (this.locationMarkers) {
            this.locationMarkers.off("click", this.clickedOnLocation);
            this.props.map.removeLayer(this.locationMarkers);
        }

        this.locationMarkers = L.featureGroup(
            this.props.albums.map(album => {
                const thumbnail = this.props.albumThumbnails[album._id];
                const options = {
                    icon:
                        (thumbnail &&
                            L.divIcon({
                                className: album.open ? "open" : "",
                                iconSize: [thumbnail.width / 2, thumbnail.height / 2],
                                html: `<img class="${this.props.classes.album}" src="${
                                    thumbnail.dataUrl
                                }" alt="${album.title}"/><div class="${
                                    this.props.classes.albumCover
                                }"></div><div class="${
                                    this.props.classes.icon
                                }">${ReactDOMServer.renderToStaticMarkup(
                                    album.open ? <EditIcon /> : <PhotoLibraryIcon />,
                                )}</div>`,
                            })) ||
                        new L.Icon.Default(),
                };
                const marker = L.marker(pointToLeaflet(album.coords), options);
                (marker as MyLocationMarker).album = album;
                return marker;
            }),
        );
        this.locationMarkers.on("click", this.clickedOnLocation);

        const thumbnailIdsToDownload = this.props.albums
            .filter(album => !this.props.albumThumbnails[album._id])
            .map(album => album._id);
        this.locationMarkers.once("add", () =>
            this.thumbnailDownloader.startWork(thumbnailIdsToDownload),
        );

        this.props.map.addLayer(this.locationMarkers);
    }

    render() {
        return <></>;
    }
}

const { addAlbumThumbnail, editAlbum, openAlbumOnMap } = actions;
export default connect(
    (state: State) => ({
        albums: selectors.getAlbums(state),
        albumThumbnails: selectors.getAlbumThumbnails(state),
    }),
    { addAlbumThumbnail, editAlbum, openAlbumOnMap },
)(withStyles(styles)(AlbumsOnMap));
